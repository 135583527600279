<script lang="ts">
    import applyStyle from 'Lib/Utils/ApplyStyle';
    import * as Style from 'Components/Style';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let gap: Nullable<string> = Style.spacing.gap;
    export let padding: Nullable<string> = Style.column.padding;
    export let verticalAlign: Nullable<string> = Style.column.verticalAlign;
    export let horizontalAlign: Nullable<string> = Style.column.horizontalAlign;
    export let backgroundColor: Nullable<string> = Style.column.backgroundColor;
    export let border: Nullable<string> = undefined;
    export let width: Nullable<string> = Style.unset;
    export let minWidth: Nullable<string> = Style.unset;
    export let position: Nullable<string> = Style.initial;
    
    export let overflow: Nullable<string> = 'clip';

    $: style = {
        width,
        gap,
        padding,
        border,
        backgroundColor,
        minWidth,
        position,
        alignItems: horizontalAlign,
        justifyContent: verticalAlign,
        overflow,
    };
</script>

<div
    class="column"
    use:applyStyle={style}>
    <slot />
</div>

<style>
    .column {
        display: flex;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
    }
</style>
