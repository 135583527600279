import SingletonDependency from 'Lib/SingletonDependency';
import { get, writable, type Writable } from 'svelte/store';
import type { Nullable } from 'Lib/Utils/Nullable';
import type {User} from "Generated/RestClient.g";

export default class UserContext extends SingletonDependency {
    public currentUserObservable: Writable<Nullable<User>> = writable(undefined);
    public autoLoginAllowed: boolean = false;

    public get currentUser(): Nullable<User> {
        return get(this.currentUserObservable);
    }

    public set currentUser(user: Nullable<User>) {
        this.currentUserObservable.set(user);
    }
}
