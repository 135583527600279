import {LogLevel, PublicClientApplication} from '@azure/msal-browser';
import LoginProvider from 'Lib/LoginProvider';
import {LoginRequest} from 'Generated/RestClient.g';

const AZURE_USERNAME_KEY = 'azure-username';

export default class AzureActiveDirectoryLoginProvider implements LoginProvider {
    static readonly staticKey = 'azure-ad';
    readonly key = AzureActiveDirectoryLoginProvider.staticKey;

    private readonly msal: PublicClientApplication;

    constructor(config: any) {
        const msalConfig: any = {
            auth: {},
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level: LogLevel, message: any, containsPii: any) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    },
                },
            },
        };

        config.navigateToLoginRequestUrl = false;
        msalConfig.auth = config;
        this.msal = new PublicClientApplication(msalConfig);
    }

    public async silentLogin() {
        await this.msal.initialize();
        const username = localStorage.getItem(AZURE_USERNAME_KEY);
        if (!username) throw new Error('Username missing');

        const loginRequest = {
            scopes: ['openid', 'profile', 'User.Read'],
            loginHint: username,
        };

        const result = await this.msal.ssoSilent(loginRequest);
        if (result?.accessToken) {
            return new LoginRequest({azure: {token: result.accessToken}});
        }

        localStorage.removeItem(AZURE_USERNAME_KEY);
        throw new Error('Invalid login');
    }

    public async login(data: any, storeUserName: boolean = false) {
        await this.msal.initialize();
        const loginRequest = {
            scopes: ['openid', 'profile', 'User.Read'],
            forceRefresh: false,
        };

        let loginPopupResult = await this.msal.loginPopup(loginRequest);
        if (loginPopupResult?.accessToken) {
            if (storeUserName) localStorage.setItem(AZURE_USERNAME_KEY, loginPopupResult.account.username);
            return new LoginRequest({azure: {token: loginPopupResult.accessToken}});
        }

        throw new Error('No result received from login popup');
    }

    public async logout() {
        localStorage.removeItem(AZURE_USERNAME_KEY);
    }
}
