﻿export const translations = {
    global: {
        actions: {
            logout: 'Logout',
            login: 'Login',
            profile: 'Profile',
            save: 'Save',
            exportToPdf: 'Export to PDF',
            saveAsNew: 'Save as new',
            fullscreen: 'Toggle fullscreen',
            retry: 'Retry',
        },
        messages: {
            pleaseWait: 'Please wait…',
            unknownError: 'Unknown error',
            errorWhileFetchingVersion: 'Error while fetching version',
        },
        banner: {
            internalVersion: 'Internal version',
        },
        version: 'Version: {0}',
        errors: {
            fullscreenNotSupported: 'This browser doesn\'t support fullscreen functionality',
        },

    },
    pages: {
        login: {
            title: 'Publication Server Authentication',
            fields: {
                username: 'Username',
                password: 'Password',
            },
            loginFailed: 'Authentication failed :(',
            loginAction: 'Login',
            fetchingProviders: 'Fetching available login providers…',
        },
        publication: {
            customLinksListView: {
                tableHeaders: {
                    itemName: 'Link name',
                    createdBy: 'Created by',
                    createdAt: 'Created at',
                    updatedBy: 'Updated by',
                    updatedAt: 'Updated at',
                },
                labels: {
                    noDescription: 'No description.',
                },
                tooltips: {
                    createNewCustomLink: 'Create new custom link',
                },
                searchBox: {
                    placeholder: 'Select a presentation…',
                    clearTooltip: 'Clear',
                    unknownPresentation: 'unknown (different owner)',
                    noPresentations: 'No presentations uploaded.',
                },
                actions: {
                    delete: 'Delete',
                    openScenario: 'Open custom link',
                    copyScenarioURI: 'Copy custom link',
                    copyEmbedCode: 'Copy embed code',
                    shareScenario: 'Share custom link',
                    edit: 'Edit',
                },
                noContent:
                    'No custom links found. Create a new one by pressing the "+" icon above the table.',
            },
            presentationListView: {
                header: {
                    itemName: 'Publication name',
                    createdBy: 'Created by',
                    createdAt: 'Created at',
                    updatedBy: 'Updated by',
                    updatedAt: 'Updated at',
                },
                actions: {
                    download: 'Download',
                    delete: 'Delete',
                    openScenario: 'Open presentation',
                    copyScenarioURI: 'Copy presentation link',
                    copyEmbedCode: 'Copy embed code',
                    shareScenario: 'Share presentation',
                    edit: 'Edit',
                },
                noContent:
                    'No published ActiveGraf presentation found. Start by uploading from a presentation\'s Task Pane or drop an "actg" file here.',
                noSearchMatch: 'The search request did not match any scenario.',
                sharedWith: 'Shared with {0}.',
                enableScenarioClone: 'Enable scenario cloning.',
                disableScenarioClone: 'Disable scenario cloning.',
                unnamedPresentation: 'Unnamed presentation',
                elementCount: '{0} out of {1} items',
            },
            actions: {
                upload: 'Upload',
                filterScenarios: 'Search scenarios',
            },
            labels: {
                presentationListViewToggle: 'Presentations',
                customLinksViewToggle: 'Custom Links',
            },
            dragAndDropDescription: 'Release the file start uploading',
            presentationDeletionDialog: {
                title: 'Presentation deletion',
                description: 'Are you sure you want to delete "{0}" publication?',
                connectedLinkWarning: 'This presentation is connected to the following custom link:',
                connectedLinksWarning: 'This presentation is connected to the following custom links:',
                confirmAction: 'Confirm',
                cancelAction: 'Cancel',
            },
            customLinkDeletionDialog: {
                title: 'Custom link deletion',
                description: 'Are you sure you want to delete "{0}" custom link?',
                connectedPresentationWarning: 'This custom link is connected to "{0}" presentation.',
                confirmAction: 'Confirm',
                cancelAction: 'Cancel',
            },
            invalidActgFile: 'Invalid `actg` file: {0}',
            invalidLicenseError: 'Invalid server license. Please contact your system administrator!',
            publicationURICopied: 'Publication URL copied successfully.',
            embedCodeCopied: 'Embed code copied successfully.',
            passwordChangeDialog: {
                title: 'Publication password',
                description: 'Enter a password for the publication here, or leave it empty to clear the current password',
                confirmAction: 'Confirm',
                cancelAction: 'Cancel',
            },
            passwordProtectionTooltip: 'Password protection',
            invalidPassword: 'The password must contain other characters than spaces',
            customLinksEditor: {
                errors: {
                    errorCreatingCustomLink: 'Error creating custom link',
                    errorSavingCustomLink: 'Error saving custom link',
                    nameAlreadyExists: 'A custom link with this name exists already.',
                    matchRequiredFormat: 'The name cannot contain special characters, cannot be longer than {0} characters, and cannot be empty.',
                },
                messages: {
                    created: 'Created',
                    saved: 'Saved',
                },
                inputs: {
                    nameLabel: 'Custom link name:',
                    namePlaceholder: 'Name of the custom link…',
                    descriptionLabel: 'Description:',
                    descriptionPlaceholder: 'Description…',
                    connectedPresentationLabel: 'Connected presentation:',
                },
                buttons: {
                    cancel: 'Cancel',
                    create: 'Create',
                    save: 'Save',
                },
            },
        },
        presentation: {
            presentationAlreadyRunning: 'Presentation already running in this browser',
            genericCommunicationError: 'Failed to communicate with the running AGCore',
            saveSucceeded: 'Save succeeded.',
            saveFailed: 'Save failed.',
            leaveConfirmationText: 'If you close the page any unsaved changes will be lost.',
            missingScenarioId: 'The scenario ID is missing.',
            missingScenario: 'The requested scenario was not found.',
            concurrentLimitReached: 'Server limit reached, please try again later.',
            unknownError: 'Unknown error',
            pdfExportFailed: 'PDF export failed',
            exportInProgress: 'Please wait while we prepare your exported document.',
            passwordPromptDialog: {
                title: 'Publication password',
                description: 'This publication is password protected',
                placeholder: 'Please enter the password here',
                confirmAction: 'Confirm',
                incorrectPassword: 'Incorrect password',
            },
            saveAsNewDialog: {
                title: 'Create a copy from this presentation',
                email: 'E-mail',
                placeholder: 'Your e-mail address',
                saveAsNew: 'Save as new',
                publicationURICopied: 'Publication URL copied successfully.',
                saveFailed: 'Could not save your presentation.',
                close: 'Close',
                open: 'Open',
                saveSuccessfulDescription: 'Presentation saved successfully. Please save the URL to use it later.',
            },
            startPresentation: 'Start',
            startPageTitle: 'ActiveGraf Presentation',
            customLinkHasNoPresentation: 'This link does not seem to have a presentation yet.',
        },
        containerLoading: {
            startingNewInstance: 'Starting a new ActiveGraf instance',
        },
    },
    loginFrame: {
        labels: {
            currentlyLoggedInUser: 'Currently logged in user: {0}',
            currentPublicationServer: 'Current Publication Server: {0}',
            fetchingLoginStatus: 'Fetching login status…',
        },
        messages: {
            loginFailed: 'Login failed',
            logoutFailed: 'Logout failed',
            loginSuccess: 'Successfully logged in',
            logoutSuccess: 'Successfully logged out',
        },
    },
};
