﻿import SingletonDependency from 'Lib/SingletonDependency';
import DependencyContainer from 'Lib/DependencyContainer';
import CustomLinkContext from 'Pages/PublicationPage/Lib/CustomLinkContext';
import Sorter from 'Pages/PublicationPage/Lib/Sorter';
import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';
import NotificationService from 'Lib/Services/NotificationService';
import {CustomLink, CustomLinksClient} from 'Generated/RestClient.g';
import UserService from 'Lib/Services/UserService';
import translations from 'Assets/i18n';
import PublicationListService from 'Pages/PublicationPage/Lib/Services/PublicationListService';

export default class CustomLinkService extends SingletonDependency {
    private readonly context: CustomLinkContext;
    private readonly sorter: Sorter;
    private readonly notificationService: NotificationService;
    private readonly client = new CustomLinksClient();
    private translationContext = translations.pages.publication;

    constructor(applicationContext: DependencyContainer) {
        super(applicationContext);
        this.context = applicationContext.get(CustomLinkContext);
        this.notificationService = applicationContext.get(NotificationService);
        this.sorter = new Sorter(this.context);
        
        const presentationListService = applicationContext.get(PublicationListService);
        presentationListService.onPresentationDeleted.do(this.onPresentationDeleted.bind(this));

        const userService = applicationContext.get(UserService);
        userService.onLogin.do(async () => {
            await this.updateCustomLinkListAsync();
        });

        userService.onLogout.do(() => {
            this.context.customLinksList = [];
            this.context.pendingLinksCount = 0;
            this.context.itemToEdit = undefined;
        });

        userService.isUserLoggedInAsync().then(async isLoggedIn => {
            if (isLoggedIn) {
                await this.updateCustomLinkListAsync();
            }
        });
    }

    public async onPresentationDeleted(presentationId: string) {
        for (const customLink of this.context.customLinksList) {
            if (customLink.presentationId === presentationId) customLink.presentationId = undefined;
        }
    }

    public async toggleSort(key: ColumnKey) {
        this.sorter.toggleSort(key);
        return this.updateCustomLinkListAsync();
    }

    public async updateCustomLinkListAsync(): Promise<void> {
        const orders = this.context.listSorting.map(
            entry => entry.key + (entry.order === 'desc' ? '|desc' : ''),
        );

        const list = await this.client.getCustomLinks(orders);
        this.context.customLinksList = list.results! as CustomLink[];
    }

    public async createNewCustomLink(link: CustomLink) {
        await this.client.createCustomLink(link);
        return this.updateCustomLinkListAsync();
    }

    public async deleteCustomLink(link: CustomLink) {
        await this.client.deleteCustomLink(link.id);
        return this.updateCustomLinkListAsync();
    }

    public async editCustomLink(link: CustomLink) {
        await this.client.editCustomLink(link.id, link);
        return this.updateCustomLinkListAsync();
    }

    public checkNameAlreadyExists(name: string) {
        name = name.toLowerCase();
        return !!this.context.customLinksList.find((l) => l.name.toLowerCase() == name);
    }

    private generateURL = (linkId: string) => `${window.location.origin}/#/${linkId}`;
    private generateEmbedCode = (linkId: string) => `<iframe src="${window.location.origin}/s/${linkId}?autostart=false&disableSlideNavigation=false&slide=1" height="320" width="480" style="border: none;"></iframe>`;

    public async openCustomLink(linkId: string) {
        const link = this.context.customLinksList.find((l) => l.id == linkId);
        if (!link) return;
        window.open(this.generateURL(link.name));
    }

    public async copyCustomLink(linkId: string) {
        const link = this.context.customLinksList.find((l) => l.id == linkId);
        if (!link) return;
        await navigator.clipboard.writeText(this.generateURL(link.name));
        this.notificationService.info(this.translationContext.publicationURICopied);
    }

    public async copyCustomLinkEmbedCode(linkId: string) {
        const link = this.context.customLinksList.find((l) => l.id == linkId);
        if (!link || !link.embedId) return;
        await navigator.clipboard.writeText(this.generateEmbedCode(link.embedId));
        this.notificationService.info(this.translationContext.embedCodeCopied);
    }

    public getConnectedLinks(presentationId?: string): CustomLink[] | undefined {
        if (!presentationId) return undefined;

        return this.context.customLinksList.filter((l) => l.presentationId == presentationId);
    }
}
